<template>
  <pageBack>
    <div class="main">
      <p style="background-color:#ffffff; margin:8pt 0pt 32pt; text-align:center">
        <span
          style="color:#333333;  font-size:16.5pt; font-weight:bold"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:16.5pt; font-weight:bold"
        >
          Privacy Policy</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >Table of Contents</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >A. COLLECTION OF INFORMATION</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >- Information That You Provide to Us</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >- Information That We Collect Automatically</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >- Information That We Receive From Third Parties</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >B. USE OF </span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >PERSONAL INFORMA</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >TION</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >C. </span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >DISCLOSURE OR SHARING OF </span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >PERSONAL INFORMATION</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >D. RETENTION</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >E.</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >LEGAL BASES FOR COLLECTING YOUR </span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >PERSONAL INFORMATION</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
        >
          FOR USERS/VISITORS FROM EUROPEAN UNION</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >F. </span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >YOUR PRIVACY RIGHTS AND CHOICES</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >G. INTERNATIONAL TRANSFERS OF PERSONAL INFORMATION</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >H. COOKI</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >ES</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >I. MINORS</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >J. SECURITY MEASURES</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >K. CHANGES TO THIS PRIVACY POLICY</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >L</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >. HOW TO CONTACT US</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >We</span
        ><span
          style="color:#333333;  font-size:9pt"
          >, </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          (“</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >we</span
        ><span
          style="color:#333333;  font-size:9pt"
          >”, or “</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >us</span
        ><span
          style="color:#333333;  font-size:9pt"
          >”) recognize the importance of privacy and confidentiality of
          personal information.</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          (or the </span
        ><span
          style="color:#333333;  font-size:9pt"
          >“</span
        ><span
          style="color:#333333;  font-size:9pt"
          >Platform</span
        ><span
          style="color:#333333;  font-size:9pt"
          >”</span
        ><span
          style="color:#333333;  font-size:9pt"
          >)</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >is</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          a business to consumer </span
        ><span
          style="color:#333333;  font-size:9pt"
          >(or "</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >B2C</span
        ><span
          style="color:#333333;  font-size:9pt"
          >") </span
        ><span
          style="color:#333333;  font-size:9pt"
          >provided by</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >HONGKONG XUNHAI TECHNOLOGY LIMITED, with its registered office at
          FLAT/RM A 12/F KIU FU COMMERCIAL BLDG, 300 LOCKHART ROAD, WAN CHAI
          HK</span
        ><span
          style="color:#333333;  font-size:9pt"
          >, </span
        ><span
          style="color:#333333;  font-size:9pt"
          >which connects and facilitates sales and purchases between business
          sellers (or "</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >Sellers</span
        ><span
          style="color:#333333;  font-size:9pt"
          >") and c</span
        ><span
          style="color:#333333;  font-size:9pt"
          >onsumer buyers (or "</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >Buyers</span
        ><span
          style="color:#333333;  font-size:9pt"
          >")</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.
        </span>
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >This Privacy Policy sets out the ways in which we collect, use and
          disclose information in connection with the operation of the Platform,
          including personal information about you, Platform users, visitors and
          representatives.</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          This Privacy Policy also applies to use of the Platform via your
          mobile device.</span
        >
      </p>
      <h1
        style="font-size:18pt; line-height:150%; margin:5pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0"
      >
        <span style=" font-size:18pt; font-weight:bold"
          >A. COLLECTION OF INFORMATION</span
        >
      </h1>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >The personal information that we collect broadly falls into the
          following categories:</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt; font-weight:bold; text-decoration:underline"
          >Information That You Provide To Us:</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; widows:0"
      >
        <span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >If you are a Buyer</span
        ><span
          style="color:#333333;  font-size:9pt"
          >, we </span
        ><span
          style="color:#333333;  font-size:9pt"
          >will ask you to provide:</span
        >
      </p>
      <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >contact information</span
          ><span
            style="color:#333333;  font-size:9pt"
            >&#xa0;</span
          ><span
            style="color:#333333;  font-size:9pt"
            >including: name; address; phone number; email address;</span
          >
        </li>
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >purchase and/or payment information</span
          ><span
            style="color:#333333;  font-size:9pt"
            >&#xa0;</span
          ><span
            style="color:#333333;  font-size:9pt"
            >including: credit card or similar payment</span
          ><span
            style="color:#333333;  font-size:9pt"
          >
          </span
          ><span
            style="color:#333333;  font-size:9pt"
            >method</span
          ><span
            style="color:#333333;  font-size:9pt"
          >
            that you intend to purchase with; billing and delivery
            address;</span
          >
        </li>
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >informa</span
          ><span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >tion about your purchases</span
          ><span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >&#xa0;</span
          ><span
            style="color:#333333;  font-size:9pt"
            >including: the goods purchased and </span
          ><span
            style="color:#333333;  font-size:9pt"
            >quantity</span
          ><span
            style="color:#333333;  font-size:9pt"
            >;</span
          >
        </li>
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >information about and/or to support complaints</span
          ><span
            style="color:#333333;  font-size:9pt"
            >&#xa0;</span
          ><span
            style="color:#333333;  font-size:9pt"
            >made in connection with the Platform or goods and/or services
            purchased via the Platform;</span
          >
        </li>
      </ul>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >If you are a Seller (i.e. an employee or represe</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >ntative of a Seller</span
        ><span
          style="color:#333333;  font-size:9pt"
          >, we will ask you to provide:</span
        >
      </p>
      <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >contact information</span
          ><span
            style="color:#333333;  font-size:9pt"
            >&#xa0;</span
          ><span
            style="color:#333333;  font-size:9pt"
            >including: name; address; phone number; email address</span
          >
        </li>
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >information about the Seller's business</span
          ><span
            style="color:#333333;  font-size:9pt"
            >&#xa0;</span
          ><span
            style="color:#333333;  font-size:9pt"
            >(if applicable) including corporate registration, business licences
            (if applicable), tax or s</span
          ><span
            style="color:#333333;  font-size:9pt"
            >imilar information;</span
          >
        </li>
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >details about the goods and products that the Seller intends to
            sell</span
          ><span
            style="color:#333333;  font-size:9pt"
            >&#xa0;</span
          ><span
            style="color:#333333;  font-size:9pt"
            >via the Platform;</span
          >
        </li>
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >purchase and/or payment information</span
          ><span
            style="color:#333333;  font-size:9pt"
            >&#xa0;</span
          ><span
            style="color:#333333;  font-size:9pt"
            >including </span
          ><span
            style="color:#333333;  font-size:9pt"
            >your beneficiary account (PayPal account or Stripe account)</span
          ><span
            style="color:#333333;  font-size:9pt"
          >
            or other payment information that the Selle</span
          ><span
            style="color:#333333;  font-size:9pt"
            >r intends to be paid by.</span
          >
        </li>
      </ul>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >If you choose to provide us</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
          >with any additional information, such as in response to
          questionnaires for market research / analytics. This may include
          marital status, nationality, gender, date and/or year of birth, annual
          income, mon</span
        ><span
          style="color:#333333;  font-size:9pt"
          >thly spending and user preferences. If you prefer not to provide such
          information, the use of our services and products will not be
          affected.</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt; font-weight:bold; text-decoration:underline"
          >Information That We Collect Automatically:</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >If you are a Buyer or Seller or prospective Buyer or Seller we will
          aut</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >omatically collect:</span
        >
      </p>
      <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >A copy of any correspondence with us</span
          ><span
            style="color:#333333;  font-size:9pt"
            >, including any verification information we ask for, if you contact
            us for any reason (such as our customer service department chat
            service, telephone or email).</span
          >
        </li>
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >Details of Platform buying and browsin</span
          ><span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >g activities</span
          ><span
            style="color:#333333;  font-size:9pt"
            >, for these purposes we will automatically collect information from
            your device. In some countries, including countries in the European
            Economic Area and the UK, this information may be considered as
            personal information under applicable data p</span
          ><span
            style="color:#333333;  font-size:9pt"
            >rotection laws. Specifically this collected information may include
            IP addresses, device type, unique device identification numbers,
            browser type, broad geographic location (e.g. country or city-level
            location), browsing patterns and details of how you hav</span
          ><span
            style="color:#333333;  font-size:9pt"
            >e interacted with our Platform and the goods and services available
            on it. In addition, we gather information about the Platform and
            visitors to the Platform including browser software, operating
            system, software and hardware attributes, pages viewed, numb</span
          ><span
            style="color:#333333;  font-size:9pt"
            >er of sessions and unique visitors. Some of this information may be
            collected using cookies and similar tracking technology, as
            explained further under the section </span
          ><span
            style="color:#333333;  font-size:9pt"
            >H</span
          ><span
            style="color:#333333;  font-size:9pt"
          >
          </span
          ><span
            style="color:#333333;  font-size:9pt"
            >“</span
          ><span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >COOKIES</span
          ><span
            style="color:#333333;  font-size:9pt"
            >” below. Where we are required by applicable law, we will seek your
            prior consent </span
          ><span
            style="color:#333333;  font-size:9pt"
            >to the collection of information using cookies and similar tracking
            technologies.</span
          >
        </li>
      </ul>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt; font-weight:bold; text-decoration:underline"
          >Information That We Receive From Third Parties:</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >If you are a Buyer or Seller we may receive personal information
          about you from:</span
        >
      </p>
      <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >Social media platforms if you choose to regis</span
          ><span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >ter for a Platform account via a social media account.</span
          ><span
            style="color:#333333;  font-size:9pt"
            >&#xa0;</span
          ><span
            style="color:#333333;  font-size:9pt"
            >Exactly what information we receive will depend on your privacy
            settings with the applicable platform, but would typically include
            basic public profile information such as:</span
          >
        </li>
      </ul>
      <p
        style="background-color:#ffffff; margin:5pt 0pt 5pt 15.05pt; text-indent:21pt"
      >
        <span
          style="color:#333333;  font-size:9pt"
          >• Your social media user nam</span
        ><span
          style="color:#333333;  font-size:9pt"
          >e, nickname or username</span
        >
      </p>
      <p
        style="background-color:#ffffff; margin:5pt 0pt 5pt 15.05pt; text-indent:21pt"
      >
        <span
          style="color:#333333;  font-size:9pt"
          >• Your social media profile picture</span
        >
      </p>
      <p
        style="background-color:#ffffff; margin:5pt 0pt 5pt 15.05pt; text-indent:21pt"
      >
        <span
          style="color:#333333;  font-size:9pt"
          >• Country</span
        >
      </p>
      <p
        style="background-color:#ffffff; margin:5pt 0pt 5pt 15.05pt; text-indent:21pt"
      >
        <span
          style="color:#333333;  font-size:9pt"
          >• Company name (if applicable).</span
        >
      </p>
      <h1
        style="font-size:18pt; line-height:150%; margin:5pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0"
      >
        <span style=" font-size:18pt; font-weight:bold"
          >B. USE OF </span
        ><span style=" font-size:18pt; font-weight:bold"
          >PERSONAL INFORMATION</span
        >
      </h1>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >We collect and use your personal information for the following
          purposes:</span
        >
      </p>
      <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >verifying Platform account registrations</span
          ><span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >&#xa0;</span
          ><span
            style="color:#333333;  font-size:9pt"
            >including</span
          ><span
            style="color:#333333;  font-size:9pt"
          >
            conducting fraud, security, "Know Your Customer" and anti-money
            laundering checks in order to approve your Seller account;</span
          >
        </li>
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >setting up / administering Buyer and Seller accounts</span
          ><span
            style="color:#333333;  font-size:9pt"
            >, including providing log-in IDs and setting up payment /
            transaction accounts;</span
          >
        </li>
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >providing customer service support</span
          ><span
            style="color:#333333;  font-size:9pt"
            >, responding to your queries, feedback and managing claims and/or
            disputes;</span
          >
        </li>
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >providing/supporting general Platform services</span
          ><span
            style="color:#333333;  font-size:9pt"
            >, facilitating communication between Buyers and Sellers, processing
            transactions and payments, and p</span
          ><span
            style="color:#333333;  font-size:9pt"
            >roviding logistics and delivery services;</span
          >
        </li>
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >providing relevant </span
          ><span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >user</span
          ><span
            style="color:#333333;  font-size:9pt; font-weight:bold"
          >
            benefits</span
          ><span
            style="color:#333333;  font-size:9pt"
            >&#xa0;</span
          ><span
            style="color:#333333;  font-size:9pt"
            >including coupons</span
          ><span
            style="color:#333333;  font-size:9pt"
            >;</span
          >
        </li>
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >verifying your identity and purchases in connection with product
            warranty or other after-sales claims</span
          ><span
            style="color:#333333;  font-size:9pt"
            >;</span
          >
        </li>
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >assessing and monitoring account security and transaction r</span
          ><span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >isks</span
          ><span
            style="color:#333333;  font-size:9pt"
            >&#xa0;</span
          ><span
            style="color:#333333;  font-size:9pt"
            >of Buyers and Sellers, detecting and preventing fraud, money
            laundering and other security incidents;</span
          >
        </li>
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >conducting research and analytics</span
          ><span
            style="color:#333333;  font-size:9pt"
            >&#xa0;</span
          ><span
            style="color:#333333;  font-size:9pt"
            >to ensure the security of our Platform, to improve the content and
            layout of the Platform, and to improve our produ</span
          ><span
            style="color:#333333;  font-size:9pt"
            >ct offerings and services;</span
          >
        </li>
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt"
            >cookies or other similar technologies may be used to provide you
            with advertising based upon your browsing activities and interests
            (see section </span
          ><span
            style="color:#333333;  font-size:9pt"
            >H</span
          ><span
            style="color:#333333;  font-size:9pt"
            >. COOKIES below for more information).</span
          >
        </li>
      </ul>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >We may also use your personal information f</span
        ><span
          style="color:#333333;  font-size:9pt"
          >or other purposes that are not incompatible with the purposes we have
          disclosed to you if and where this is permitted by applicable data
          protection laws.</span
        >
      </p>
      <h1
        style="font-size:18pt; line-height:150%; margin:5pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0"
      >
        <span style=" font-size:18pt; font-weight:bold"
          >C. DISCLOSURE OR SHARING OF </span
        ><span style=" font-size:18pt; font-weight:bold"
          >PERSONAL INFORMATION</span
        >
      </h1>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >We disclose/share personal information with the follo</span
        ><span
          style="color:#333333;  font-size:9pt"
          >wing categories of recipients:</span
        >
      </p>
      <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >Other Platform Buyers and/or Sellers</span
          ><span
            style="color:#333333;  font-size:9pt"
            >&#xa0;</span
          ><span
            style="color:#333333;  font-size:9pt"
            >where necessary to support purchases and communications made
            between you. If you are a Buyer, Sellers that you purchase from will
            also control</span
          ><span
            style="color:#333333;  font-size:9pt"
          >
          </span
          ><span
            style="color:#333333;  font-size:9pt"
            >personal information about you relevant to co</span
          ><span
            style="color:#333333;  font-size:9pt"
            >nnected with your Platform purchases and communications with them.
            Please refer to Seller Privacy Policies for information about how
            they will process your information.</span
          >
        </li>
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >Third party partners and service providers and/or affiliates</span
          ><span
            style="color:#333333;  font-size:9pt"
            >&#xa0;</span
          ><span
            style="color:#333333;  font-size:9pt"
            >engaged by or working with</span
          ><span
            style="color:#333333;  font-size:9pt"
          >
            us to assist us to provide services to you or who otherwise process
            personal information for purposes described in this Privacy Policy
            or notified to you when we collect your personal information.
            Categories of these partners and service providers include</span
          ><span
            style="color:#333333;  font-size:9pt"
            >:</span
          >
        </li>
      </ul>
      <p style="background-color:#ffffff; margin:5pt 0pt 5pt 36pt">
        <span
          style="color:#333333;  font-size:9pt"
          >•</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
        >
          group entities and affiliated companies</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
          >and/or their designated service providers that work with us to
          provide processing services such as software, tools, systems and
          messaging services for purposes described in this Privacy
          Policy;</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt 5pt 36pt">
        <span
          style="color:#333333;  font-size:9pt"
          >•</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >paymen</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >t service providers</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
          >that provide payment processing services. The activities of payment
          service providers may be governed by their own privacy policies, not
          this Privacy Policy;</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt 5pt 36pt">
        <span
          style="color:#333333;  font-size:9pt"
          >•</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >logistics partners</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >for providing delivery services for Buyers and Sellers</span
        ><span
          style="color:#333333;  font-size:9pt"
          >, i</span
        ><span
          style="color:#333333;  font-size:9pt"
          >ncluding return and exchange of products, and warehousing services
          for Sellers;</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt 5pt 36pt">
        <span
          style="color:#333333;  font-size:9pt"
          >•</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >cloud computing or other hosting service providers</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
          >to provide cloud storage services;</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt 5pt 36pt">
        <span
          style="color:#333333;  font-size:9pt"
          >•</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >third party rating / reviewing service providers</span
        ><span
          style="color:#333333;  font-size:9pt"
          >&#xa0;</span
        ><span
          style="color:#333333;  font-size:9pt"
          >to carry out reviews of our services </span
        ><span
          style="color:#333333;  font-size:9pt"
          >with customers if you choose to participate in reviewing or rating
          Platform products and/or services;</span
        >
      </p>
      <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >To our professional advisers, law enforcement agencies, insurers,
            government and regulatory and other organisations</span
          ><span
            style="color:#333333;  font-size:9pt"
            >&#xa0;</span
          ><span
            style="color:#333333;  font-size:9pt"
            >where we believe it is necessary to co</span
          ><span
            style="color:#333333;  font-size:9pt"
            >mply with applicable laws or to exercise, establish or defend our
            legal rights or protect your vital interests or those of any other
            person; or as otherwise required or permitted by applicable
            laws.</span
          >
        </li>
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >To an actual or potential buyer</span
          ><span
            style="color:#333333;  font-size:9pt"
            >&#xa0;</span
          ><span
            style="color:#333333;  font-size:9pt"
            >(and its agents and advis</span
          ><span
            style="color:#333333;  font-size:9pt"
            >ers) in connection with any actual or proposed purchase, merger or
            acquisition of any part of our business, provided that we inform the
            buyer it must use your personal information only for the purposes
            disclosed in this Privacy Policy.</span
          >
        </li>
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >To any other person </span
          ><span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >with your consent to the disclosure</span
          ><span
            style="color:#333333;  font-size:9pt"
            >.</span
          >
        </li>
      </ul>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >We have established relationships with other parties and websites to
          offer you the benefit of products and services which we do not offer.
          We offer you access to these other parties and their websites either
          through the</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          use of hyperlinks to these sites from our Platform or through offering
          "co-branded" sites in which both we and other parties share the same
          uniform resource locator (URL), domain name or pages within a domain
          name on the Internet. In some cases you may be</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          required to submit personal information to register or apply for
          products or services provided by such third parties or co-branded
          partners. This Privacy Policy does not apply to these third party
          sites or co-branded sites. The privacy policies of those o</span
        ><span
          style="color:#333333;  font-size:9pt"
          >ther parties may differ from ours, and we have no control over the
          information that you submit to those third parties. You should read
          the relevant privacy policy for those third party sites and co-branded
          sites before responding to any offers, products or</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          services advertised by those parties.</span
        >
      </p>
      <h1
        style="font-size:18pt; line-height:150%; margin:5pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0"
      >
        <span style=" font-size:18pt; font-weight:bold"
          >D. RETENTION</span
        >
      </h1>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >We will retain your personal information for as long as we have an
          ongoing legitimate business need to do so, to provide services or
          products to you, as required or permitted by applicable laws. When we
          h</span
        ><span
          style="color:#333333;  font-size:9pt"
          >ave no ongoing legitimate business need to process your personal
          information, we will either delete or anonymise it, or if this is not
          possible (for example, because your personal information has been
          stored in backup archives), then we will securely store</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          your personal information and isolate it from any further processing
          until deletion is possible. How long your data is kept for will also
          depend on how you use our website (for example if you are logged in as
          a </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Member </span
        ><span
          style="color:#333333;  font-size:9pt"
          >or user, </span
        ><span
          style="color:#333333;  font-size:9pt"
          >or you are visiting our webs</span
        ><span
          style="color:#333333;  font-size:9pt"
          >ite as a guest) and what the information you provide to us is used
          for. For example, if you are a registered </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Member</span
        ><span
          style="color:#333333;  font-size:9pt"
          >, certain data will be retained for a length of time following
          termination or deletion of your account, in order to comply with
          applicable la</span
        ><span
          style="color:#333333;  font-size:9pt"
          >ws or in case of disputes. In other instances, the period of
          retention may be determined according to your activity on the Platform
          or to comply with a specific arbitration period/resolve a
          dispute.</span
        >
      </p>
      <h1
        style="font-size:18pt; line-height:150%; margin:5pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0"
      >
        <span style=" font-size:18pt; font-weight:bold"
          >E. </span
        ><span style=" font-size:18pt; font-weight:bold"
          >LEGAL BASES FOR COLLECTING YOUR </span
        ><span style=" font-size:18pt; font-weight:bold"
          >PERSONAL INFORMATION</span
        ><span style=" font-size:18pt; font-weight:bold">
          F</span
        ><span style=" font-size:18pt; font-weight:bold"
          >OR </span
        ><span style=" font-size:18pt; font-weight:bold"
          >USERS/</span
        ><span style=" font-size:18pt; font-weight:bold"
          >VISITORS FROM EUROPEAN UNION</span
        >
      </h1>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >This section applies if you are based in the European Economic Area
          (which for the purposes of this Privacy Policy shall include the UK in
          the event of the UK's departure from the European Union) ("</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >EEA</span
        ><span
          style="color:#333333;  font-size:9pt"
          >").</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >Please also n</span
        ><span
          style="color:#333333;  font-size:9pt"
          >ote, if you are a Buyer, Sellers will also act as a data controller
          of personal information about</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          you</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          and your Platform purchases and communications with them. Please refer
          to the Privacy Policies of those Sellers for more information about
          the ways in whi</span
        ><span
          style="color:#333333;  font-size:9pt"
          >ch they will process such information.</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >Legal Basis for Data Processing</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >We process personal information for the purposes set out in this
          Privacy Policy. Our legal basis to process for processing your
          personal information will depend on the personal informat</span
        ><span
          style="color:#333333;  font-size:9pt"
          >ion concerned and the specific context in which we collect it but
          will include where it is:</span
        >
      </p>
      <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt"
            >necessary for the performance of contract between you and FunPinPin
            (for example, to provide you with the services you request and to
            identify and authenticate you </span
          ><span
            style="color:#333333;  font-size:9pt"
            >so you may use the Platform);</span
          >
        </li>
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt"
            >necessary to comply with legal requirements (for example, to comply
            with applicable accounting rules and to make mandatory disclosures
            to law enforcement);</span
          >
        </li>
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt"
            >necessary for our legitimate interests and not overridden by your
            righ</span
          ><span
            style="color:#333333;  font-size:9pt"
            >ts; and/or where it is based on your consent.</span
          >
        </li>
      </ul>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >If we collect and use your personal information in reliance on our
          legitimate interests (or those of any third party), this interest will
          normally be to operate our Platform and services, manage our
          relationshi</span
        ><span
          style="color:#333333;  font-size:9pt"
          >p with you and communicate with you as necessary to provide our
          services to you and for our legitimate commercial interest, for
          instance, when responding to your queries, improving our Platform and
          our services, undertaking marketing, or for the purposes o</span
        ><span
          style="color:#333333;  font-size:9pt"
          >f ensuring the security of our Platform and services and detecting or
          preventing illegal activities such as fraud. We may have other
          legitimate interests and if appropriate we will make clear to you at
          the relevant time what those legitimate interests are.</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >If we ask you to provide personal information to comply with a legal
          requirement or to enter into a contract with you, we will make this
          clear at the relevant time and advise you whether the provision of
          your personal information is mandatory or not (as w</span
        ><span
          style="color:#333333;  font-size:9pt"
          >ell as of the possible consequences if you do not provide your
          personal information). In some instances, you may be required to
          provide us with personal information for processing as described
          above, in order for us to be able to provide you all of our ser</span
        ><span
          style="color:#333333;  font-size:9pt"
          >vices, and for you to use all the features of our Platform.</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >If you have questions about or need further information concerning
          the legal basis on which we collect and use your personal information,
          please contact us using the contact details provided under</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          section </span
        ><span
          style="color:#333333;  font-size:9pt"
          >L</span
        ><span
          style="color:#333333;  font-size:9pt"
          >. HOW TO CONTACT US below.</span
        >
      </p>
      <h1
        style="font-size:18pt; line-height:150%; margin:5pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0"
      >
        <span style=" font-size:18pt; font-weight:bold"
          >F. </span
        ><span style=" font-size:18pt; font-weight:bold"
          >YOUR PRIVACY RIGHTS</span
        ><span style=" font-size:18pt; font-weight:bold">
          AND </span
        ><span style=" font-size:18pt; font-weight:bold"
          >CHOICES</span
        >
      </h1>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >Under applicable laws, you may have rights of access to personal
          information held by us and other rights.
        </span>
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >Your Rights</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >Y</span
        ><span
          style="color:#333333;  font-size:9pt"
          >ou have the following data protection rights, which you can
          exer</span
        ><span
          style="color:#333333;  font-size:9pt"
          >cise at any time by contacting us using the contact details provided
          under section </span
        ><span
          style="color:#333333;  font-size:9pt"
          >L</span
        ><span
          style="color:#333333;  font-size:9pt"
          >. HOW TO CONTACT US below:</span
        >
      </p>
      <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt"
            >The right to</span
          ><span
            style="color:#333333;  font-size:9pt"
            >&#xa0;</span
          ><span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >access, correct, update or request deletion</span
          ><span
            style="color:#333333;  font-size:9pt"
            >&#xa0;</span
          ><span
            style="color:#333333;  font-size:9pt"
            >of your personal information.</span
          >
        </li>
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt"
            >The right to</span
          ><span
            style="color:#333333;  font-size:9pt"
            >&#xa0;</span
          ><span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >object to processing</span
          ><span
            style="color:#333333;  font-size:9pt"
            >&#xa0;</span
          ><span
            style="color:#333333;  font-size:9pt"
            >of your personal informa</span
          ><span
            style="color:#333333;  font-size:9pt"
            >tion when it is based on our legitimate interests.</span
          >
        </li>
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt"
            >The right to ask us, in some situations, to</span
          ><span
            style="color:#333333;  font-size:9pt"
            >&#xa0;</span
          ><span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >restrict processing</span
          ><span
            style="color:#333333;  font-size:9pt"
            >&#xa0;</span
          ><span
            style="color:#333333;  font-size:9pt"
            >of your personal information or request portability of your
            personal information.</span
          >
        </li>
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt"
            >If we have collected and process your personal information </span
          ><span
            style="color:#333333;  font-size:9pt"
            >with your consent, then you have the right to</span
          ><span
            style="color:#333333;  font-size:9pt"
            >&#xa0;</span
          ><span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >withdraw your consent at any time</span
          ><span
            style="color:#333333;  font-size:9pt"
            >. Withdrawing your consent will not affect the lawfulness of any
            processing we conducted prior to your withdrawal, nor will it affect
            processing of your personal information con</span
          ><span
            style="color:#333333;  font-size:9pt"
            >ducted in reliance on lawful processing grounds other than
            consent</span
          >
        </li>
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt"
            >The right</span
          ><span
            style="color:#333333;  font-size:9pt"
            >&#xa0;</span
          ><span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >to complain to a data protection authority</span
          ><span
            style="color:#333333;  font-size:9pt"
            >&#xa0;</span
          ><span
            style="color:#333333;  font-size:9pt"
            >about our collection and use of your personal information. For more
            information, please contact your local data protection
            authority.</span
          >
        </li>
      </ul>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >We</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          respond to all requests we receive from individuals wishing to
          exercise their data protection rights in accordance with applicable
          data protection laws.</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt; font-weight:normal"
          >If you are aware of changes or inaccuracies in your information, you
          should inform us of such changes </span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:normal"
          >so that our records may be updated or corrected.</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >Your </span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >Choices</span
        >
      </p>
      <ul type="disc" style="margin:0pt; padding-left:0pt">
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >You can choose not to provide us with </span
          ><span
            style="color:#333333;  font-size:9pt; font-weight:bold"
            >PERSONAL INFORMATION</span
          ><span
            style="color:#333333;  font-size:9pt"
            >. </span
          ><span
            style="color:#333333;  font-size:9pt"
            >If you choose to do this, you can continue to use part of the
            Service, but we will not be able to process transactions or use part
            of </span
          ><span
            style="color:#333333;  font-size:9pt"
            >the service without </span
          ><span
            style="color:#333333;  font-size:9pt"
            >PERSONAL INFORMATION</span
          ><span
            style="color:#333333;  font-size:9pt"
            >.</span
          >
        </li>
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt"
            >You can turn off cookies in your browser by changing its
            settings</span
          ><span
            style="color:#333333;  font-size:9pt"
            >.</span
          ><span
            style="color:#333333;  font-size:9pt"
            >You can block cookies by activating a setting on your browser
            allowing you to refuse cookies. You can also delete cookies through
            your browser setti</span
          ><span
            style="color:#333333;  font-size:9pt"
            >ngs. If you turn off cookies, you can continue to use the website
            and browse its pages, but Service especially certain features will
            not work effectively.
          </span>
        </li>
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt"
            >You can ask us not to use your data for marketing</span
          ><span
            style="color:#333333;  font-size:9pt"
            >.</span
          ><span
            style="color:#333333;  font-size:9pt"
            >We will inform you (before collecting your data) i</span
          ><span
            style="color:#333333;  font-size:9pt"
            >f we intend to use your data for marketing and if third parties are
            involved. You can opt out from marketing by emailing us</span
          ><span
            style="color:#333333;  font-size:9pt"
            >.</span
          >
        </li>
        <li
          style="background-color:#ffffff; color:#333333;  font-size:10pt; margin:5pt 0pt 5pt 27.6pt; padding-left:8.4pt; text-indent:0pt"
        >
          <span
            style="color:#333333;  font-size:9pt"
            >You can limit in-app advertising or block certain Advertising by
            reset your advertising IDs.</span
          >
        </li>
      </ul>
      <p style="background-color:#ffffff; margin:5pt 0pt 5pt 36pt">
        <span
          style="color:#333333;  font-size:9pt"
          >For iOS devices and Android devices, y</span
        ><span
          style="color:#333333;  font-size:9pt"
          >ou may refer to the following links to limit or remove some un-wanted
          ads.
        </span>
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt 5pt 36pt">
        <span
          style="color:#333333;  font-size:9pt"
          >iOS - </span
        ><a href="https://support.apple.com/en-us/HT202074"
          ><span
            style="color:#0000ff;  font-size:9pt; text-decoration:underline"
            >https://support.apple.com/en-us/HT202074</span
          ></a
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt 5pt 36pt">
        <span
          style="color:#333333;  font-size:9pt"
          >Android - https://support.google.com/ads/answer/2662922?hl=en
        </span>
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt 5pt 36pt">
        <span
          style="color:#333333;  font-size:9pt"
          >For any other</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          devices and/or operating systems, please visit the privacy settings
          for the applicable devices/the respective operating systems, or
          contact the applicable platform and/or applications store operator in
          order to opt-out of tailored in-application advertise</span
        ><span
          style="color:#333333;  font-size:9pt"
          >ments.</span
        >
      </p>
      <h1
        style="font-size:18pt; line-height:150%; margin:5pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0"
      >
        <span style=" font-size:18pt; font-weight:bold"
          >G. </span
        ><span style=" font-size:18pt; font-weight:bold"
          >INTERNATIONAL TRANSFERS OF PERSONAL INFORMATION</span
        >
      </h1>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >In connection with providing the services through our Platform, we
          will transfer your personal information to countries outside </span
        ><span
          style="color:#333333;  font-size:9pt"
          >your country</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          (including to Mainland China </span
        ><span
          style="color:#333333;  font-size:9pt"
          >and </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Hong Kong Special Admini</span
        ><span
          style="color:#333333;  font-size:9pt"
          >strative Region). </span
        ><span
          style="color:#333333;  font-size:9pt"
          >You Acknowledge and agree that w</span
        ><span
          style="color:#333333;  font-size:9pt"
          >e store information about </span
        ><span
          style="color:#333333;  font-size:9pt"
          >your visits to the Platform </span
        ><span
          style="color:#333333;  font-size:9pt"
          >in the People's Republic of China, and we may also store this
          information on servers and equipment in other countries.</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >We take appropriate steps to ensu</span
        ><span
          style="color:#333333;  font-size:9pt"
          >re that recipients of your personal information are bound to duties
          of confidentiality and we implement appropriate measures to ensure
          your personal information will remain protected in accordance with
          this Privacy Policy.</span
        >
      </p>
      <h1
        style="font-size:18pt; line-height:150%; margin:5pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0"
      >
        <span style=" font-size:18pt; font-weight:bold"
          >H</span
        ><span style=" font-size:18pt; font-weight:bold"
          >. COOKIES</span
        >
      </h1>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >This section explains </span
        ><span
          style="color:#333333;  font-size:9pt"
          >how we use cookies and similar technologies (we refer to them
          collectively as "</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >cookies</span
        ><span
          style="color:#333333;  font-size:9pt"
          >") to recognize you when you visit our Platform. It explains what
          these technologies are and why we use them, as well as your rights to
          control them.</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >What are cookies?</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >A </span
        ><span
          style="color:#333333;  font-size:9pt"
          >cookie is a string of information that is sent to your browser and
          stored on your computer's or mobile device’s hard drive. If you do not
          de-activate or erase the cookie, each time you use the same browser or
          device to access the Platform, our web servers </span
        ><span
          style="color:#333333;  font-size:9pt"
          >will be notified of your visit to the Platform and in turn we may
          have knowledge of your visit and the pattern of your usage.</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >Cookies set by the site or app owner (in this case, </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
          >) are called "</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >first party cookies</span
        ><span
          style="color:#333333;  font-size:9pt"
          >". Cookies set by third parties othe</span
        ><span
          style="color:#333333;  font-size:9pt"
          >r than the website owner are called "</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >third party cookies</span
        ><span
          style="color:#333333;  font-size:9pt"
          >". Third party cookies enable third party features or functionality
          to be provided on or through the website (e.g. like advertising,
          interactive content and analytics). The parties that set these
          thir</span
        ><span
          style="color:#333333;  font-size:9pt"
          >d party cookies can recognize your device both when it visits the
          website in question and also when it visits certain other
          websites.</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >Cookies are also categorized as "</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >session cookies</span
        ><span
          style="color:#333333;  font-size:9pt"
          >" (which are erased once you close the browser or shut down the
          device) an</span
        ><span
          style="color:#333333;  font-size:9pt"
          >d "</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >persistent cookies</span
        ><span
          style="color:#333333;  font-size:9pt"
          >" (which remain even after closing the browser or shutting down the
          device/application and which are activated each time you visit the
          Platform). Session cookies use encrypted data to authenticate you.
          Persistent cookies do not store a</span
        ><span
          style="color:#333333;  font-size:9pt"
          >ccount numbers or passwords. We use both session cookies and
          persistent cookies.</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >Why we use cookies?</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >We use first and third party cookies for several reasons.</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >Some cookies are required for technical and/or security reasons in
          order for our Platform to oper</span
        ><span
          style="color:#333333;  font-size:9pt"
          >ate, and we refer to these as "essential" or "strictly necessary"
          cookies. First party cookies are mainly served to enable basic
          functionality on our Platform; including web analytics cookies that
          help us understand how users use our web pages and how we c</span
        ><span
          style="color:#333333;  font-size:9pt"
          >an improve their use. For example we use essential or strictly
          necessary cookies to enable us to remember goods added to your
          shopping basket and to recognize you when you sign into your
          account.</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >We also use social media cookies. You may register for Platf</span
        ><span
          style="color:#333333;  font-size:9pt"
          >orm </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Membership</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          and "share" Platform content through social media such as Facebook and
          Twitter. Sometimes we embed videos from websites like YouTube. These
          websites have their own privacy policies which govern their use of
          information, which you can find on</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          the respective websites.</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >We also use cookies to enable us to track and target the interests of
          Platform visitors to enhance the experience on our Platform by
          researching visiting patterns and conduct interest-based advertising;
          assisting our partners to t</span
        ><span
          style="color:#333333;  font-size:9pt"
          >rack user visits to the Platforms; and to track progress and
          participation in promotions.
        </span>
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >What types of cookies do we use</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >The types of cookies used by FunPinPin can be classified into the
          following categories according to International Chamber of
          Commerc</span
        ><span
          style="color:#333333;  font-size:9pt"
          >e UK Cookie Guide.</span
        >
      </p>
      <table
        cellspacing="0"
        cellpadding="0"
        style="border-collapse:collapse; margin-left:0pt; width:388.35pt"
      >
        <tr style="height:15.75pt">
          <td
            style="background-color:#ffffff; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:4.88pt; padding-right:4.88pt; vertical-align:middle; width:80.1pt"
          >
            <p style="background-color:#ffffff; margin:5pt 0pt">
              <span
                style="color:#333333;  font-size:9pt; font-weight:bold"
                >Type of Cookie</span
              >
            </p>
          </td>
          <td
            style="background-color:#ffffff; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.25pt; padding-right:4.88pt; vertical-align:middle; width:286.5pt"
          >
            <p style="background-color:#ffffff; margin:5pt 0pt">
              <span
                style="color:#333333;  font-size:9pt; font-weight:bold"
                >Purposes</span
              >
            </p>
          </td>
        </tr>
        <tr style="height:109.5pt">
          <td
            style="background-color:#ffffff; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; padding-left:4.88pt; padding-right:4.88pt; vertical-align:middle; width:80.1pt"
          >
            <p style="background-color:#ffffff; margin:5pt 0pt">
              <span
                style="color:#333333;  font-size:9pt; font-weight:bold"
                >Cookies</span
              ><span
                style="color:#333333;  font-size:9pt; font-weight:bold"
              >
              </span
              ><span
                style="color:#333333;  font-size:9pt; font-weight:bold"
                >Necessary for</span
              ><span
                style="color:#333333;  font-size:9pt; font-weight:bold"
              >
              </span
              ><span
                style="color:#333333;  font-size:9pt; font-weight:bold"
                >Services</span
              >
            </p>
          </td>
          <td
            style="background-color:#ffffff; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; padding-left:5.25pt; padding-right:4.88pt; vertical-align:middle; width:286.5pt"
          >
            <p style="background-color:#ffffff; margin:5pt 0pt">
              <span
                style="color:#333333;  font-size:9pt"
                >These cookies are essential and necessary to operate
                FunPinPin</span
              ><span
                style="color:#333333;  font-size:9pt"
              >
                sites and to use some of</span
              ><span
                style="color:#333333;  font-size:9pt"
              >
              </span
              ><span
                style="color:#333333;  font-size:9pt"
                >its features. If these cookies are b</span
              ><span
                style="color:#333333;  font-size:9pt"
                >locked or disabled, some of </span
              ><span
                style="color:#333333;  font-size:9pt"
                >FunPinPin we</span
              ><span
                style="color:#333333;  font-size:9pt"
                >bsite's functions</span
              ><span
                style="color:#333333;  font-size:9pt"
              >
                will not be</span
              ><span
                style="color:#333333;  font-size:9pt"
              >
              </span
              ><span
                style="color:#333333;  font-size:9pt"
                >available to you and you may not be able to use our online
                services.</span
              >
            </p>
          </td>
        </tr>
        <tr style="height:106.5pt">
          <td
            style="background-color:#ffffff; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; padding-left:4.88pt; padding-right:4.88pt; vertical-align:middle; width:80.1pt"
          >
            <p style="background-color:#ffffff; margin:5pt 0pt">
              <span
                style="color:#333333;  font-size:9pt; font-weight:bold"
                >Functionality</span
              ><span
                style="color:#333333;  font-size:9pt; font-weight:bold"
              >
              </span
              ><span
                style="color:#333333;  font-size:9pt; font-weight:bold"
                >Cookies</span
              >
            </p>
          </td>
          <td
            style="background-color:#ffffff; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; padding-left:5.25pt; padding-right:4.88pt; vertical-align:middle; width:286.5pt"
          >
            <p style="background-color:#ffffff; margin:5pt 0pt">
              <span
                style="color:#333333;  font-size:9pt"
                >These cookies</span
              ><span
                style="color:#333333;  font-size:9pt"
              >
              </span
              ><span
                style="color:#333333;  font-size:9pt"
                >are used to improve the functionality of FunPinPin websites,
                enhance the presentation of content on FunPinPin sites and make
                them easier </span
              ><span
                style="color:#333333;  font-size:9pt"
                >for you to use. For example, these cookies allow FunPinPin
                to:</span
              ><span
                style="color:#333333;  font-size:9pt"
                >&#xa0;</span
              ><span
                style="color:#333333;  font-size:9pt"
                >Save your specific preferences, such as currency preference,
                language</span
              ><span
                style="color:#333333;  font-size:9pt"
              >
              </span
              ><span
                style="color:#333333;  font-size:9pt"
                >preference, as well as interests or past items reviewed;</span
              ><span
                style="color:#333333;  font-size:9pt"
                >&#xa0;</span
              ><span
                style="color:#333333;  font-size:9pt"
                >Remember your log status.</span
              >
            </p>
          </td>
        </tr>
        <tr style="height:108pt">
          <td
            style="background-color:#ffffff; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; padding-left:4.88pt; padding-right:4.88pt; vertical-align:middle; width:80.1pt"
          >
            <p style="background-color:#ffffff; margin:5pt 0pt">
              <span
                style="color:#333333;  font-size:9pt; font-weight:bold"
                >Performance</span
              ><span
                style="color:#333333;  font-size:9pt; font-weight:bold"
              >
              </span
              ><span
                style="color:#333333;  font-size:9pt; font-weight:bold"
                >Cookies</span
              >
            </p>
          </td>
          <td
            style="background-color:#ffffff; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; padding-left:5.25pt; padding-right:4.88pt; vertical-align:middle; width:286.5pt"
          >
            <p style="background-color:#ffffff; margin:5pt 0pt">
              <span
                style="color:#333333;  font-size:9pt"
                >These cookies are u</span
              ><span
                style="color:#333333;  font-size:9pt"
                >sed to obtain statistics about the numbe</span
              ><span
                style="color:#333333;  font-size:9pt"
                >r of visitors to our websites</span
              ><span
                style="color:#333333;  font-size:9pt"
              >
              </span
              ><span
                style="color:#333333;  font-size:9pt"
                >and how our visitors use our websites, to </span
              ><span
                style="color:#333333;  font-size:9pt"
                >assess the performance of our</span
              ><span
                style="color:#333333;  font-size:9pt"
              >
              </span
              ><span
                style="color:#333333;  font-size:9pt"
                >websites. For example, these cookies allow FunPinPin to
                asse</span
              ><span
                style="color:#333333;  font-size:9pt"
                >ss how users interact with the</span
              ><span
                style="color:#333333;  font-size:9pt"
              >
              </span
              ><span
                style="color:#333333;  font-size:9pt"
                >content of the web page</span
              ><span
                style="color:#333333;  font-size:9pt"
                >s so we can improve our website.</span
              >
            </p>
          </td>
        </tr>
        <tr style="height:64.5pt">
          <td
            style="background-color:#ffffff; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; padding-left:4.88pt; padding-right:4.88pt; vertical-align:middle; width:80.1pt"
          >
            <p style="background-color:#ffffff; margin:5pt 0pt">
              <span
                style="color:#333333;  font-size:9pt; font-weight:bold"
                >A</span
              ><span
                style="color:#333333;  font-size:9pt; font-weight:bold"
                >dvertising/Targeting</span
              ><span
                style="color:#333333;  font-size:9pt; font-weight:bold"
              >
                Cookies</span
              >
            </p>
          </td>
          <td
            style="background-color:#ffffff; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; padding-left:5.25pt; padding-right:4.88pt; vertical-align:middle; width:286.5pt"
          >
            <p style="background-color:#ffffff; margin:5pt 0pt">
              <span
                style="color:#333333;  font-size:9pt"
                >These cookies assist us to make our websites and apps,
                and</span
              ><span
                style="color:#333333;  font-size:9pt"
              >
                the advertising displayed on </span
              ><span
                style="color:#333333;  font-size:9pt"
                >FunPinPin and third party websites and on o</span
              ><span
                style="color:#333333;  font-size:9pt"
                >ur apps more relevant to your </span
              ><span
                style="color:#333333;  font-size:9pt"
                >interests. These cookies allow </span
              ><span
                style="color:#333333;  font-size:9pt"
                >FunPinPin to understand the usefulness to you of</span
              ><span
                style="color:#333333;  font-size:9pt"
              >
              </span
              ><span
                style="color:#333333;  font-size:9pt"
                >the advertisements and content has been delivered to you</span
              >
            </p>
          </td>
        </tr>
      </table>

      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >What about other tracking technologies, like web beacons?</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >Cookies are not the only way to recognise or track visitors to our
          Platform. We may use o</span
        ><span
          style="color:#333333;  font-size:9pt"
          >ther, similar technologies from time to time, like web beacons
          (sometimes called "tracking pixels" or "clear gifs"). These are tiny
          graphics files that contain a unique identifier that enable us to
          recognise when someone has visited our Platform.</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt; font-weight:bold"
          >How can I</span
        ><span
          style="color:#333333;  font-size:9pt; font-weight:bold"
        >
          control cookies?</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >You can determine if and how a cookie will be accepted by configuring
          the privacy setting of the browser you are using to access the
          Platform or the "privacy setting" of your device. As the means by
          which you can refuse cookies through yo</span
        ><span
          style="color:#333333;  font-size:9pt"
          >ur web browser controls vary from browser-to-browser, you should
          visit your browser's help menu for more information. If you adjust the
          privacy setting in the browser, your mobile device will continue
          collecting data unless you adjust the privacy setting o</span
        ><span
          style="color:#333333;  font-size:9pt"
          >f the device, and vice versa.</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >You have the right to decide whether to accept or reject cookies. If
          you choose to set your web browser controls to reject cookies, you may
          still use our website though your access to some functionality and
          areas of our websit</span
        ><span
          style="color:#333333;  font-size:9pt"
          >e may be restricted.</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >Certain features of the Platform depend on cookies. Please be aware
          that if you choose to block cookies, you may not be able to sign in or
          use those features, and preferences that are dependent on cookies may
          be lost.</span
        >
      </p>
      <h1
        style="font-size:18pt; line-height:150%; margin:5pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0"
      >
        <span style=" font-size:18pt; font-weight:bold"
          >I</span
        ><span style=" font-size:18pt; font-weight:bold"
          >. MINORS</span
        >
      </h1>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >THE PLA</span
        ><span
          style="color:#333333;  font-size:9pt"
          >TFORM IS INTENDED FOR ADULTS AND USERS MUST BE OVER 18 TO USE OUR
          PLATFORM AND WE DO NOT INTEND TO SELL ANY OF OUR PRODUCTS OR SERVICES
          TO MINORS. IF A MINOR HAS PROVIDED US WITH PERSONAL INFORMATION
          WITHOUT PARENTAL OR GUARDIAN CONSENT, THE PARENT OR GUAR</span
        ><span
          style="color:#333333;  font-size:9pt"
          >DIAN SHOULD CONTACT US TO REMOVE THE INFORMATION.</span
        >
      </p>
      <h1
        style="font-size:18pt; line-height:150%; margin:5pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0"
      >
        <span style=" font-size:18pt; font-weight:bold"
          >J</span
        ><span style=" font-size:18pt; font-weight:bold"
          >. SECURITY MEASURES</span
        >
      </h1>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >We implement appropriate technical and organisational measures to
          prevent unauthorized access to the Site, to maintain data accuracy and
          to ensure the correct use of the information we </span
        ><span
          style="color:#333333;  font-size:9pt"
          >hold. Upon becoming aware of a data breach, we will notify you and
          the regulatory authorities in accordance with the timescales and scope
          required by applicable data protection law.</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >For registered users of the Site, some of your information can be
          viewed a</span
        ><span
          style="color:#333333;  font-size:9pt"
          >nd edited through your account, which is protected by a password. We
          recommend that you do not divulge your password to anyone. Our
          personnel will never ask you for your password in an unsolicited phone
          call or in an unsolicited email. If you share a compu</span
        ><span
          style="color:#333333;  font-size:9pt"
          >ter with others, you should not choose to save your log-in
          information (e.g., user ID and password) on that shared computer.
          Remember to sign out of your account and close your browser window
          when you have finished your session.</span
        >
      </p>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >No data transmission over t</span
        ><span
          style="color:#333333;  font-size:9pt"
          >he internet or any wireless network can be guaranteed to be perfectly
          secure. As a result, while we use appropriate technical and
          organisational measures to protect the information we hold for you, we
          cannot guarantee the security of any information you tr</span
        ><span
          style="color:#333333;  font-size:9pt"
          >ansmit to over the internet.</span
        >
      </p>
      <h1
        style="font-size:18pt; line-height:150%; margin:5pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0"
      >
        <span style=" font-size:18pt; font-weight:bold"
          >K</span
        ><span style=" font-size:18pt; font-weight:bold"
          >. CHANGES TO THIS PRIVACY POLICY</span
        >
      </h1>
      <p style="background-color:#ffffff; margin:5pt 0pt">
        <span
          style="color:#333333;  font-size:9pt"
          >We may update this Privacy Policy from time to time in response to
          changing legal, technical or business developments. When we update our
          Privacy Policy, we will take appropriate measures to in</span
        ><span
          style="color:#333333;  font-size:9pt"
          >form you, consistent</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
        </span
        ><span
          style="color:#333333;  font-size:9pt"
          >with the significance of the changes we make, such as by posting an
          amended Privacy Policy on the Platform.</span
        >
      </p>
      <h1
        style="font-size:18pt; line-height:150%; margin:5pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; text-align:justify; widows:0"
      >
        <span style=" font-size:18pt; font-weight:bold"
          >L</span
        ><span style=" font-size:18pt; font-weight:bold"
          >. HOW TO CONTACT US</span
        >
      </h1>
      <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
        <span
          style="color:#333333;  font-size:9pt"
          >Please also feel free to contact us if you have any questions about </span
        ><span
          style="color:#333333;  font-size:9pt"
          >FunPinPin</span
        ><span
          style="color:#333333;  font-size:9pt"
        >
          Privacy Policy or the inform</span
        ><span
          style="color:#333333;  font-size:9pt"
          >ation practices of the </span
        ><span
          style="color:#333333;  font-size:9pt"
          >Platform</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.</span
        >
      </p>
      <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
        <span
          style="color:#333333;  font-size:9pt"
          >You may contact us via the email address </span
        ><span
          style="color:#333333;  font-size:9pt"
          >or to the following address: </span
        ><span
          style="color:#333333;  font-size:9pt"
          >HONGKONG XUNHAI TECHNOLOGY LIMITED, with its registered office at
          FLAT/RM A 12/F KIU FU COMMERCIAL BLDG, 300 LOCKHART ROAD, WAN CHAI
          HK</span
        ><span
          style="color:#333333;  font-size:9pt"
          >.</span
        >
      </p>
    </div>
  </pageBack>
</template>

<script>
import pageBack from "@/components/basic/pageBack.vue";
export default {
  components: {
    pageBack,
  },
};
</script>
<style lang="scss" scoped>
.main {
  padding:  40px;
  box-sizing: border-box;
  font-family: PingFangSC-Regular, PingFang SC!important;
}
</style>
